import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Reminder from "./Reminder";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { listenToRemindersFromFirestore } from "../../firestore/firestoreService";
import { listenToReminders } from "./redux/ReminderAction";
import { Dimmer, Grid, Segment, Loader, Divider } from "semantic-ui-react";
import SearchReminderByName from "./SearchReminderByName";
import SearchReminderByCategory from "./SearchReminderByCategory";

export default function Reminders() {
  const dispatch = useDispatch();
  const { reminders } = useSelector((state) => state.reminders);
  const { loading } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => listenToRemindersFromFirestore(),
    data: (reminders) => dispatch(listenToReminders(reminders)),
    deps: [dispatch],
  });

  return (
    <Grid textAlign="center" stackable>
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ) : (
        <>
          <Segment
            style={{
              marginTop: 100,
              border: "#d3d3d3 solid thin",
              width: "100%",
            }}
          >
            <SearchReminderByName />

            <SearchReminderByCategory />
          </Segment>

          <Grid.Row style={{ marginTop: 20, marginBottom: 100 }} columns={5}>
            {reminders.map((reminder) => {
              if (reminder.isActive) {
                return <Reminder key={reminder.id} reminder={reminder} />;
              }
            })}
          </Grid.Row>
          <Divider horizontal>Inactive Reminders</Divider>
          <Grid.Row
            style={{ marginTop: 40, backgroundColor: "#cecece" }}
            columns={5}
          >
            {reminders.map((reminder) => {
              if (!reminder.isActive) {
                return <Reminder key={reminder.id} reminder={reminder} />;
              }
            })}
          </Grid.Row>
        </>
      )}
    </Grid>
  );
}
