import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import NavBar from "../Nav/NavBar";
import { Container, Button, FormGroup, Header } from "semantic-ui-react";
import {
  yearArray,
  monthArray,
  dayArray,
  categoryOptions,
} from "./dateConsts/DateConsts";
import LoadingComponent from "../LoadingComponent";
import { Form, Formik } from "formik";
import MyTextInput from "./forms/MyTextInput";
import MySelectInput from "./forms/MySelectInput";
import MyTextareaInput from "./forms/MyTextareaInput";
import { addReminderToFirestore } from "../../firestore/firestoreService";
import { toast } from "react-toastify";

export default function AddNewReminder() {
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.async);
  const initialValues = {
    name: "",
    expDate: "",
    location: "",
    category: "",
    notes: "",
    isActive: true,
    month: "",
    year: "",
    day: "",
    // files: [],
  };

  const allDays = dayArray.map((day) => ({ key: day, text: day, value: day }));
  const allMonths = monthArray.map((month) => ({
    key: month,
    text: month,
    value: month,
  }));
  const allYears = yearArray.map((year) => ({
    key: year,
    text: year,
    value: year,
  }));

  // const validFileTypes = { file: ["pdf", "doc", "docx"] };

  const validationSchema = Yup.object({
    name: Yup.string().required("Please name your reminder"),
    category: Yup.string().required("Please select a category."),
    location: Yup.string(),
    isActive: Yup.boolean(),
    // expDate: Yup.string().required("Please select an expiration date."),
    notes: Yup.string(),
    // files: Yup.array(),
  });

  if (loading) return <LoadingComponent content="loading event..." />;

  if (error) return navigate("/error");

  return (
    <>
      <NavBar />
      <Container>
        <Button
          style={{ marginTop: 140 }}
          icon="home"
          basic
          onClick={() => navigate("/reminders")}
        />
      </Container>
      <Container style={{ padding: 40 }}>
        <Header
          style={{ marginBottom: 20 }}
          as="h3"
          content="Add a new reminder"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            try {
              const combinedDate = `${values.year}-${values.month}-${values.day}`;
              values.expDate = combinedDate;
              addReminderToFirestore(values);
              toast.success("Your new reminder has been added successfully.");
              setSubmitting(false);
              navigate("/reminders");
            } catch (error) {
              toast.error(error.message);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form className="ui form">
              <FormGroup widths="equal" label="Expiration Date">
                <MySelectInput label="Month" options={allMonths} name="month" />
                <MySelectInput label="Day" name="day" options={allDays} />
                <MySelectInput name="year" label="Year" options={allYears} />
              </FormGroup>
              <MyTextInput
                label="Contract Name"
                name="name"
                placeholder="Name of contract reminder"
              />
              <MySelectInput
                name="category"
                label="Reminder Category"
                placeholder="Select a category here"
                options={categoryOptions}
              />
              {/* <label className="label">Files</label>
              <input type="file" multiple name="files" /> */}
              <MyTextareaInput label="Additional Notes" name="notes" />
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                content="Submit"
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
}
