import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAG3FrpFB06miJSLMGmv3-Io_eB5u3XLHA",
  authDomain: "exp-rem.firebaseapp.com",
  databaseURL: "https://exp-rem-default-rtdb.firebaseio.com",
  projectId: "exp-rem",
  storageBucket: "exp-rem.appspot.com",
  messagingSenderId: "934479431383",
  appId: "1:934479431383:web:7465bd1f0286857b5bd292",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
