import firebase from "../config/firebase";

const db = firebase.firestore();

export function getRemindersFromFirestore(observer) {
  return db.collection("reminders").onSnapshot(observer);
}

export function dataFromSnapshot(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp)
        data[prop] = data[prop].toDate();
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function listenToRemindersFromFirestore() {
  return db.collection("reminders").orderBy("expDate");
}
export function listenToReminderFromFirestore(reminderId) {
  return db.collection("reminders").doc(reminderId);
}

export function addReminderToFirestore(reminder) {
  return db.collection("reminders").add({
    ...reminder,
  });
}

export function updateReminderInFirestore(reminder) {
  return db.collection("reminders").doc(reminder.id).update(reminder);
}

export function deleteReminderFromFirestore(reminderId) {
  return db.collection("reminders").doc(reminderId).delete();
}

export function changeStatusActive(reminder) {
  return db.collection("reminders").doc(reminder.id).update({
    isActive: !reminder.isActive,
  });
}

//FILES
export function getReminderFiles(id) {
  return db.collection("files").where("locationID", "==", id);
}
export function downloadFile(fileName) {
  const storageRef = firebase.storage().ref();
  return storageRef
    .child(`${fileName}`)
    .getDownloadURL()
    .then(function (url) {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "download");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
}
export async function deleteFile(fileId, filename) {
  await firebase.storage().ref(filename).delete();
  return db.collection("files").doc(fileId).delete();
}

export function uploadReminderFiles(files) {
  return db.collection("files").add({
    ...files,
    uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
}
export async function uploadFileToStorage(file, fileInfo, hashedFileName) {
  await db.collection("files").add(fileInfo);
  const storageRef = firebase.storage().ref(hashedFileName);
  return storageRef.put(file);
}

export function setUserProfileData(user) {
  return db.collection("users").doc(user.uid).set({
    displayName: user.displayName,
    email: user.email,
  });
}

export function getUserProfile(userId) {
  return db.collection("users").doc(userId);
}

export async function updateUserProfile(profile) {
  const user = firebase.auth().currentUser;
  try {
    if (user.displayName !== profile.displayName) {
      await user.updateProfile({
        displayName: profile.displayName,
      });
    }
    return await db.collection("users").doc(user.uid).update(profile);
  } catch (error) {}
}
