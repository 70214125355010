import React from "react";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  Label,
  Segment,
  Icon,
  Container,
  Popup,
} from "semantic-ui-react";
import {
  changeStatusActive,
  deleteReminderFromFirestore,
} from "../../firestore/firestoreService";
import { toast } from "react-toastify";

export default function Reminder({ reminder }) {
  const navigate = useNavigate();
  const today = moment(new Date());
  const expirationDate = moment(reminder.expDate);
  const compareDates = expirationDate.diff(today, "days");

  const reminderColor = () => {
    if (compareDates <= 15) {
      return "red";
    } else if (compareDates <= 30) {
      return "orange";
    } else if (compareDates <= 60) {
      return "teal";
    } else if (compareDates <= 90) {
      return "blue";
    } else {
      return "blue";
    }
  };

  const reminderIcon = () => {
    if (compareDates <= 30) {
      return "warning";
    } else {
      return "thumbs up";
    }
  };

  const diffDays = (e) => {
    const today = moment(new Date());
    const expirationDate = moment(e);
    const difference = expirationDate.diff(today, "days");
    if (difference > 0) {
      return `Contract expires in: ${difference} days`;
    } else {
      return "YOUR CONTRACT HAS EXPIRED.";
    }
  };

  return (
    <Grid.Column>
      <Segment
        clearing
        raised
        color={`${reminderColor()}`}
        style={{ padding: "80px 20px 80px 20px" }}
      >
        <Header as="h3" content={reminder.name} />
        <Header
          as="h5"
          content={moment(reminder.expDate).format("MMMM Do, YYYY")}
        />
        {reminder.isActive ? (
          <Container>
            <Button.Group>
              <Popup
                content="View this contract"
                trigger={
                  <Button
                    size="mini"
                    color="blue"
                    as={NavLink}
                    to={`/reminder/${reminder.id}`}
                    icon="eye"
                  />
                }
              />
              <Popup
                content="Make this contract inactive"
                trigger={
                  <Button
                    color="grey"
                    icon="ban"
                    onClick={() => changeStatusActive(reminder)}
                  />
                }
              />
              <Popup
                content="Delete this contract permanently"
                trigger={
                  <Button
                    icon="trash"
                    color="red"
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Are you sure you want to permanently delete this reminder?"
                        )
                      ) {
                        deleteReminderFromFirestore(reminder.id);
                        toast.success(
                          "Your reminder and its files have been deleted permanently."
                        );
                        navigate("/reminders");
                      }
                    }}
                  />
                }
              />
            </Button.Group>
          </Container>
        ) : (
          <>
            <Button
              color="grey"
              size="tiny"
              basic
              content="Make Reminder Active"
              onClick={() => changeStatusActive(reminder)}
            />
            <Button
              style={{ marginTop: 6 }}
              size="tiny"
              content="Delete Reminder"
              onClick={(e) => {
                if (
                  window.confirm(
                    "Are you sure you want to permanently delete this reminder?"
                  )
                ) {
                  deleteReminderFromFirestore(reminder.id);
                  toast.success(
                    "Your reminder and its files have been deleted permanently."
                  );
                  navigate("/reminders");
                }
              }}
            />
          </>
        )}
        <Label style={{ marginTop: 20 }} color={`${reminderColor()}`}>
          <Icon name={`${reminderIcon()}`} />
          {diffDays(reminder.expDate)}
        </Label>
      </Segment>
    </Grid.Column>
  );
}
