import { combineReducers } from "redux";
import reminderReducer from "../components/reminders/redux/ReminderReducer";
import asyncReducer from "../async/asyncReducer";
import modalReducer from "../components/modals/modalReducer";
import authReducer from "../components/auth/authReducer";
import profileReducer from "../components/profile/profileReducer";
import filesReducer from "../components/reminders/reminderFiles/FilesReducer";

const rootReducer = combineReducers({
  reminders: reminderReducer,
  async: asyncReducer,
  modals: modalReducer,
  auth: authReducer,
  profile: profileReducer,
  files: filesReducer,
});

export default rootReducer;
