import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useFirestoreDoc from "../../hooks/useFirestoreDoc";
import { getUserProfile } from "../../firestore/firestoreService";
import { listenToSelectedUserProfile } from "./profileActions";
import ProfileContent from "./ProfileContent";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../components/LoadingComponent";
import NavBar from "../Nav/NavBar";

export default function ProfilePage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedUserProfile } = useSelector((state) => state.profile);
  const { currentUser } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);

  useFirestoreDoc({
    query: () => getUserProfile(id),
    data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, id],
  });

  if ((loading && !selectedUserProfile) || (!selectedUserProfile && !error))
    return <LoadingComponent content="Loading profile..." />;

  return (
    <>
      <NavBar />
      <ProfileContent
        profile={selectedUserProfile}
        isCurrentUser={currentUser.uid === selectedUserProfile.id}
      />
    </>
  );
}
