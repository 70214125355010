import {
  CREATE_REMINDER,
  DELETE_REMINDER,
  FETCH_REMINDERS,
  UPDATE_REMINDER,
} from "./ReminderConstants";

export function listenToReminders(reminders) {
  return {
    type: FETCH_REMINDERS,
    payload: reminders,
  };
}

export function createReminder(reminder) {
  return {
    type: CREATE_REMINDER,
    payload: reminder,
  };
}

export function updateReminder(reminder) {
  return {
    type: UPDATE_REMINDER,
    payload: reminder,
  };
}

export function deleteReminder(reminderId) {
  return {
    type: DELETE_REMINDER,
    payload: reminderId,
  };
}
