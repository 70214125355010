import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Grid, Select } from "semantic-ui-react";
import Reminder from "./Reminder";

export default function SearchReminderByCategory() {
  const reminders = useSelector((state) => state.reminders);
  const [searchResults, setSearchResults] = useState("");

  const findReminderByCategory = async (searchTerm) => {
    const results = reminders?.reminders.filter((reminder) =>
      reminder.category.includes(searchTerm)
    );
    await setSearchResults(results);
  };

  const categoryOptions = [
    { key: "Hardware", value: "Hardware", text: "Hardware" },
    { key: "Software", value: "Software", text: "Software" },
    { key: "Telco", value: "Telco", text: "Telco" },
    { key: "Generic", value: "Generic", text: "Generic" },
    {
      key: "Service Contract",
      value: "Service Contract",
      text: "Service Contract",
    },
    { key: "Misc", value: "Misc", text: "Misc" },
  ];

  return (
    <>
      <Form>
        <Form.Field>
          <label>Search By Category</label>
          <Select
            name="searchByCategory"
            onChange={(e, d) => findReminderByCategory(d.value)}
            placeholder="Select a category"
            options={categoryOptions}
          />
        </Form.Field>
      </Form>

      {searchResults === "" ? (
        ""
      ) : (
        <Button icon="close" onClick={() => setSearchResults("")} />
      )}

      {searchResults === "" ? null : (
        <>
          <Grid>
            <Grid.Row columns={4}>
              {searchResults.map((reminder) => {
                if (reminder.isActive) {
                  return <Reminder key={reminder.id} reminder={reminder} />;
                }
              })}
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
}
