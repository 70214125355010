import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Grid, Button } from "semantic-ui-react";
import Reminder from "./Reminder";

export default function SearchReminderByName() {
  const reminders = useSelector((state) => state.reminders);
  const [searchResults, setSearchResults] = useState("");

  const findReminderByName = async (searchTerm) => {
    const results = reminders?.reminders.filter(
      (reminder) =>
        reminder.name.toLowerCase().includes(searchTerm) ||
        reminder.notes.toLowerCase().includes(searchTerm)
    );
    await setSearchResults(results);
  };

  return (
    <>
      <Form>
        <Form.Field>
          <label>Search By Name</label>
          <input
            name="searchByName"
            placeholder="Name of contract"
            onChange={(e) => findReminderByName(e.target.value)}
          />
        </Form.Field>
      </Form>

      {searchResults === "" ? (
        ""
      ) : (
        <Button icon="close" onClick={() => setSearchResults("")} />
      )}

      {searchResults === "" ? null : (
        <>
          <Grid stackable>
            <Grid.Row columns={4}>
              {searchResults.map((reminder) => {
                if (reminder.isActive) {
                  return <Reminder key={reminder.id} reminder={reminder} />;
                }
              })}
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
}
