import "./App.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./components/HomePage";
import AddNewReminder from "./components/reminders/AddNewReminder";
import ReminderDetailsPage from "./components/reminders/reminderDetails/ReminderDetailsPage";
import ErrorComponent from "./components/ErrorComponent";
import ProfilePage from "./components/profile/ProfilePage";
import Page404 from "./components/Page404";
import RemindersDashboard from "./components/reminders/RemindersDashboard";
import { ToastContainer } from "react-toastify";
import ModalManager from "./components/modals/ModalManager";
import { useSelector } from "react-redux";
import LoadingComponent from "./components/LoadingComponent";

function App() {
  const { initialized } = useSelector((state) => state.async);
  if (!initialized) return <LoadingComponent content="Loading App..." />;
  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/reminders" element={<RemindersDashboard />} />
          <Route path="/newreminder" element={<AddNewReminder />} />
          <Route path="reminder/:id" element={<ReminderDetailsPage />} />
          <Route path="/profile/:id" element={<ProfilePage />} />
        </Route>
        <Route path="*" element={<Page404 />} />
        <Route path="/error" element={<ErrorComponent />} />
      </Routes>
      <ModalManager />
      <ToastContainer hideProgressBar position="top-right" />
    </>
  );
}

export default App;

/* <Routes>
        <Route index element={<HomePage />} />
        <>
          <Route
            path={"/*"}
            element={
              <>
                <NavBar />
                <Container className="main">
                  <Route path="/reminders" element={<RemindersDashboard />} />
                  <Route path="/newreminder" element={<AddNewReminder />} />
                  <Route
                    path="reminder/:id"
                    element={<ReminderDetailsPage />}
                  />
                  <Route path="/profile/:id" element={<ProfilePage />} />
                  <Route path="/error" element={<ErrorComponent />} />
                  <Route path="*" element={<Page404 />} />
                </Container>
              </>
            }
          />
        </>
      </Routes> */
