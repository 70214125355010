import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/DOC_logo.png";
import { Header, Icon, Segment, Button, Image } from "semantic-ui-react";

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <Segment textAlign="center" className="masthead">
        <Image src={Logo} size="small" />
        <Header color="blue" as="h1">
          <Icon name="asterisk" color="blue" />
          <Header.Content>
            Expiration Reminder
            <Header.Subheader color="orange">
              Diocese of Camden IT Department
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Button
          style={{ marginTop: 42 }}
          icon
          labelPosition="left"
          onClick={() => navigate("/reminders")}
        >
          <Icon name="sign-in" />
          Login
        </Button>
      </Segment>
    </>
  );
}
