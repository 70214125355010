import React from "react";
import moment from "moment";
import ReminderFilesDashboard from "../reminderFiles/ReminderFilesDashboard";
import { Header, Button, Card } from "semantic-ui-react";
import { openModal } from "../../modals/modalReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteReminderFromFirestore } from "../../../firestore/firestoreService";
import { toast } from "react-toastify";

export default function ReminderMainInfo({ reminder }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Card centered style={{ padding: 50 }} fluid>
        <Card.Content>
          <Header as="h1">
            {reminder.name}
            <Header.Subheader>Name</Header.Subheader>
          </Header>
          <Header as="h2">
            {moment(reminder.expDate).format("MMMM Do, YYYY")}
            <Header.Subheader>Expiration Date</Header.Subheader>
          </Header>
          <Card.Header></Card.Header>
          <Header as="h3">
            {reminder.category}
            <Header.Subheader>Category</Header.Subheader>
          </Header>
          <Header as="h3">
            {reminder.notes}
            <Header.Subheader>Notes</Header.Subheader>
          </Header>
        </Card.Content>
        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              style={{ marginTop: 44 }}
              icon="edit"
              color="teal"
              content="Edit Reminder"
              basic
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: "EditReminder",
                    modalProps: reminder,
                  })
                )
              }
            />
            <Button
              style={{ marginTop: 44 }}
              icon="trash"
              color="red"
              content="Delete Reminder"
              inverted
              onClick={(e) => {
                if (
                  window.confirm(
                    "Are you sure you want to permanently delete this reminder?"
                  )
                ) {
                  deleteReminderFromFirestore(reminder.id);
                  toast.success(
                    "Your reminder and its files have been deleted permanently."
                  );
                  navigate("/reminders");
                }
              }}
            />
          </div>
          <ReminderFilesDashboard reminder={reminder} />
        </Card.Content>
      </Card>
    </>
  );
}
