import React from "react";
import ModalWrapper from "../modals/ModalWrapper";
import * as Yup from "yup";
import { Button, FormGroup } from "semantic-ui-react";
import { Form, Formik } from "formik";
import MyTextInput from "./forms/MyTextInput";
import {
  yearArray,
  monthArray,
  dayArray,
  categoryOptions,
} from "./dateConsts/DateConsts";
import MyTextArea from "./forms/MyTextareaInput";
import MySelectInput from "./forms/MySelectInput";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { closeModal } from "../modals/modalReducer";
import { updateReminderInFirestore } from "../../firestore/firestoreService";

export default function EditReminder(reminder) {
  const dispatch = useDispatch();

  const originalReminder = useSelector((state) =>
    state.reminders.reminders.find((r) => r.id === reminder.id)
  );

  const initialValues = {
    id: originalReminder.id,
    name: originalReminder.name,
    category: originalReminder.category,
    location: originalReminder.location,
    notes: originalReminder.notes,
    year: originalReminder.year,
    month: originalReminder.month,
    day: originalReminder.day,
    expDate: originalReminder.expDate,
    isActive: originalReminder.isActive,
  };

  const allDays = dayArray.map((day) => ({ key: day, text: day, value: day }));
  const allMonths = monthArray.map((month) => ({
    key: month,
    text: month,
    value: month,
  }));
  const allYears = yearArray.map((year) => ({
    key: year,
    text: year,
    value: year,
  }));

  const validationSchema = Yup.object({
    name: Yup.string().required("Please name your reminder"),
    category: Yup.string().required("Please select a category."),
    location: Yup.string(),
    notes: Yup.string(),
    year: Yup.string(),
    month: Yup.string(),
    day: Yup.string(),
    id: Yup.string(),
  });

  return (
    <ModalWrapper size="large" header={`Edit ${reminder.name}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          try {
            const combinedDate = `${values.year}-${values.month}-${values.day}`;
            values.expDate = combinedDate;
            updateReminderInFirestore(values);
            toast.success("Your contract has been updated successfully.");
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            toast.error(error.message);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form">
            <FormGroup widths="equal" label="Expiration Date">
              <MySelectInput label="Month" options={allMonths} name="month" />
              <MySelectInput label="Day" name="day" options={allDays} />
              <MySelectInput name="year" label="Year" options={allYears} />
            </FormGroup>
            <MyTextInput label="Name" name="name" />
            <MySelectInput
              name="category"
              label="Reminder Category"
              placeholder="Select a category here"
              options={categoryOptions}
            />
            <MyTextArea name="notes" label="Additional Notes" />
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              content="Submit"
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
