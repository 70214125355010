import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useFirestoreDoc from "../../../hooks/useFirestoreDoc";
import { useParams, useNavigate } from "react-router-dom";
import { listenToReminderFromFirestore } from "../../../firestore/firestoreService";
import { listenToReminders } from "../redux/ReminderAction";
import {
  Dimmer,
  Loader,
  Segment,
  Grid,
  Button,
  Container,
} from "semantic-ui-react";
import NavBar from "../../Nav/NavBar";
import ReminderMainInfo from "./ReminderMainInfo";

export default function ReminderDetailsPage({ match }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reminder = useSelector((state) =>
    state.reminders.reminders.find((r) => r.id === id)
  );

  const { loading, error } = useSelector((state) => state.async);

  useFirestoreDoc({
    query: () => listenToReminderFromFirestore(id),
    data: (reminder) => dispatch(listenToReminders([reminder])),
    deps: [id, dispatch],
  });

  if (loading || (!reminder && !error))
    return (
      <Dimmer>
        <Loader>Loading</Loader>
      </Dimmer>
    );

  if (error) navigate("/error");

  return (
    <>
      <NavBar />
      <Segment
        placeholder
        style={{
          marginTop: 60,
          height: "auto",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container className="main">
          <Button
            floated="left"
            icon="home"
            basic
            onClick={() => navigate("/reminders")}
            content="Back to all reminders"
          />
        </Container>
        <Grid columns={1} stackable>
          <Grid.Row>
            <Grid.Column>
              <ReminderMainInfo reminder={reminder} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
