import {
  CREATE_REMINDER,
  DELETE_REMINDER,
  FETCH_REMINDERS,
  UPDATE_REMINDER,
} from "./ReminderConstants";

const initialState = {
  reminders: [],
};

export default function reminderReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_REMINDERS:
      return {
        ...state,
        reminders: payload,
      };
    case CREATE_REMINDER:
      return {
        ...state,
        reminders: [...state.reminders, payload],
      };
    case DELETE_REMINDER:
      return {
        ...state,
        reminders: [
          ...state.reminders.filter((reminder) => reminder.id !== payload),
        ],
      };
    case UPDATE_REMINDER:
      return {
        ...state,
        reminders: [
          ...state.reminders.filter((reminder) => reminder.id !== payload.id),
          payload,
        ],
      };
    default:
      return state;
  }
}
