import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";
import Logo from "../../assets/DOC_logo.png";
import SignedOutMenu from "./SignedOutMenu";
import { useSelector } from "react-redux";
import SignedInMenu from "./SignedInMenu";
import moment from "moment";

export default function NavBar() {
  const { authenticated } = useSelector((state) => state.auth);
  const pathName = useLocation();

  return (
    <Menu fixed="top">
      <Container>
        <Menu.Item as={NavLink} to="/">
          <img width={50} src={Logo} alt="Diocese IT Dept." />
        </Menu.Item>
        {pathName.pathname !== "/newreminder" ? (
          <Menu.Item
            icon="plus"
            name="Add new reminder"
            as={NavLink}
            to="/newreminder"
          />
        ) : null}
        {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
        <Menu.Item> {moment().format("MMMM Do, YYYY")} </Menu.Item>
      </Container>
    </Menu>
  );
}
