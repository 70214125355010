import React from "react";
import Reminders from "./Reminders";
import NavBar from "../Nav/NavBar";
import { Container } from "semantic-ui-react";

export default function RemindersDashboard() {
  return (
    <>
      <NavBar />
      <Container className="main">
        <Reminders />
      </Container>
    </>
  );
}
