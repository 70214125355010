import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { openModal } from "../../modals/modalReducer";

export default function ReminderFilesDashboard({ reminder }) {
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ marginTop: 20 }} className="ui two buttons">
        <Button
          color="blue"
          floated="left"
          content="Upload File"
          icon="upload"
          labelPosition="right"
          onClick={() =>
            dispatch(
              openModal({
                modalType: "UploadFile",
                modalProps: reminder,
              })
            )
          }
        />
        <Button
          color="teal"
          content="View Files"
          floated="right"
          onClick={() =>
            dispatch(
              openModal({
                modalType: "ReminderFile",
                modalProps: reminder,
              })
            )
          }
        />
      </div>
    </>
  );
}
