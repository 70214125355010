import React from "react";
import ModalWrapper from "../modals/ModalWrapper";
import { Form, Formik } from "formik";
import { Button, Header, Icon, Label } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../reminders/forms/MyTextInput";
import { signInWithEmail } from "../../firestore/firebaseService";
import { closeModal } from "../modals/modalReducer";
import { useDispatch } from "react-redux";

export default function LoginForm() {
  const dispatch = useDispatch();
  return (
    <ModalWrapper size="mini" header="">
      <Header textAlign="center" color="blue" as="h3">
        <Icon name="asterisk" color="blue" />
        <Header.Content>
          Expiration Reminder
          <Header.Subheader color="orange">
            Diocese of Camden IT Department
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await signInWithEmail(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({ auth: "Problem with your email and/or password" });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form style={{ marginTop: 50, marginBottom: 50 }} className="ui form">
            <MyTextInput name="email" placeholder="Enter email address" />
            <MyTextInput
              name="password"
              placeholder="Password"
              type="password"
            />
            {errors.auth && (
              <Label
                basic
                color="red"
                style={{ marginBottom: 10 }}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              fluid
              color="blue"
              content="Login"
            />
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
}
